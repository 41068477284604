import at from 'lodash.at'
import orderBy from 'lodash.orderby'

import { DropdownItemWithParentModel } from '@damen/ui'
import { OptionType } from '@damen/ui/lib/cjs/components/Filters/types'

import {
	FilterValueResolver,
	SortingValueResolver,
	Sorting,
	SortingType,
	SortingKeys
} from '@components/GenericOverview/types'
import { Filter, PageFilter } from './types'

export const addPagefilterOption = (
	filter: PageFilter,
	option: string,
	id: string
) => {
	filter.options.push(
		new DropdownItemWithParentModel(
			{
				checked: false,
				disabled: false,
				label: option,
				name: option.toLowerCase(),
				readOnly: false,
				value: option.toLowerCase()
			},
			id
		)
	)
}

export const validatePagefilterOption = (
	filter: PageFilter,
	filterCollection: string[],
	filterType: string
) => {
	if (filterType) {
		if (!filterCollection.includes(filterType)) {
			filterCollection.push(filterType)
			addPagefilterOption(filter, filterType, filter.name)
		}
	}
}

export const createFilterModel = (items: DropdownItemWithParentModel[]) => {
	const filterModel = new Map()

	items.forEach((item) => {
		if (!filterModel.has(item.parent)) {
			filterModel.set(item.parent, [item.label])
		} else {
			const prevVal = filterModel.get(item.parent)
			prevVal.push(item.label)
		}
	})

	return filterModel
}

export const createFilterList = (
	items: DropdownItemWithParentModel[],
	filterModel: Map<string, string[]>
) => {
	const filterList: Filter[] = []

	items.forEach((item) => {
		const parentFilterValues = filterModel.get(item.parent)

		const filter = {
			name: item.parent,
			filters: parentFilterValues
		}

		let alreadyExists = false

		filterList.forEach((x) => {
			if (x.name === filter.name) {
				alreadyExists = true
			}
		})

		if (!alreadyExists) {
			filterList.push(filter)
		}
	})

	return filterList
}

export function genericSort<T>(
	data: Array<T>,
	property: string,
	resolver: SortingValueResolver,
	order?: SortingType
): Array<T> {
	const propPath = resolver[property]
	const sortedCases = orderBy(
		data,
		(item) => at(item, propPath).toString().toUpperCase(),
		order
	)
	return sortedCases ?? data
}

export const getSortingOptions = (filterCritera: string[]): OptionType[] => {
	const tempSort: string[] = []
	const sortOptions = []

	const sortFilterOptions: OptionType[][] = filterCritera.map(
		(currFillItem) => {
			const filterLabel =
				currFillItem.charAt(0).toUpperCase() + currFillItem.slice(1)
			const ascLabel = `${filterLabel} ASC`
			const ascName = `${currFillItem}-ASC`
			const descLabel = `${filterLabel} DESC`
			const descName = `${currFillItem}-DESC`

			const asc = { label: ascLabel, value: ascName }
			const desc = { label: descLabel, value: descName }

			return [asc, desc]
		}
	)

	const filterOptsFlat = sortFilterOptions.flat()

	filterOptsFlat.push({ label: 'None', value: 'none' })

	filterOptsFlat.forEach((it) => {
		if (!tempSort.includes(it.value)) {
			sortOptions.push(it)
			tempSort.push(it.value)
		}
	})

	return sortOptions
}

export const createSortingObject = (
	sorting: Sorting,
	property: SortingKeys,
	order: SortingType
) => {
	const sortingObj = {}
	if (!order) {
		if (!sorting[property]) {
			sortingObj[property] = SortingType.asc
		} else if (sorting[property] === SortingType.asc) {
			sortingObj[property] = SortingType.desc
		} else if (sorting[property] === SortingType.desc) {
			sortingObj[property] = SortingType.asc
		}
	} else {
		sortingObj[property] = order === 'none' ? {} : order
	}
	return sortingObj
}

export function applyFilters<T>(
	data: Array<T>,
	filterList: Filter[],
	resolver: FilterValueResolver
): Array<T> {
	let filteredData: Array<T> = data
	filterList.forEach((filter) => {
		filteredData = filteredData.filter((entry) => {
			return includesFilter(entry, filter, resolver)
		})
	})
	return filteredData
}

export function includesFilter<T>(
	model: T,
	filter: Filter,
	resolver: FilterValueResolver
): boolean {
	return filter.filters
		.map((filterValue) => {
			if (resolver[filter.name]) {
				if (typeof resolver[filter.name](model) === 'boolean') {
					if (filterValue === 'Yes') {
						return resolver[filter.name](model) === true
					}

					if (filterValue === 'No') {
						return resolver[filter.name](model) === false
					}
				}
				return resolver[filter.name](model) === filterValue
			}

			return false
		})
		.reduce((prev, current) => prev || current, false)
}

export function applySorting<T>(
	data: Array<T>,
	sort: Sorting,
	resolver: SortingValueResolver
): Array<T> {
	const sortingProperty = Object.keys(sort)[0]
	const order = Object.values(sort)[0]

	if (sortingProperty && order) {
		return genericSort<T>(data, sortingProperty, resolver, order)
	}
	return data
}

export const parseTitle = (value: string | string[]) => {
	if (Array.isArray(value)) {
		return value.join(' / ')
	}
	return value
}
