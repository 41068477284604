import styled from 'styled-components'

// eslint-disable-next-line import/no-cycle
import { DownloadProps } from './ServiceRequestDownloadButton'

export const DownloadWrapper = styled.div<DownloadProps>`
	svg {
		animation: ${({ isGenerating }) =>
			isGenerating === true ? 'spinning 1.4s infinite linear ' : 'none'};
	}

	@keyframes spinning {
		0% {
			transform: rotate(0deg);
		}

		100% {
			transform: rotate(360deg);
		}
	}
`
