import React, { useState } from 'react'

import { Button, Icon, theme } from '@damen/ui'
import {
	GetServiceRequestOverviewCasesQueryVariables,
	useDownloadCasesLazyQuery
} from '@graphql/graphql'
import useError from '@hooks/useError'
// eslint-disable-next-line import/no-cycle
import { DownloadWrapper } from './styles'

export interface DownloadProps {
	isGenerating?: boolean
}

interface Props {
	variables: GetServiceRequestOverviewCasesQueryVariables
	downloadButtonText: string
	downloadButtonMobileText: string
	downloadError: string
}

const ServiceRequestDownloadButton = ({
	variables,
	downloadButtonText,
	downloadButtonMobileText,
	downloadError
}: Props) => {
	const [isGenerating, setIsGenerating] = useState(false)

	const { showError } = useError()
	const [getCasesExport] = useDownloadCasesLazyQuery({
		fetchPolicy: 'no-cache'
	})

	const downloadCases = async () => {
		setIsGenerating(true)
		try {
			const { data } = await getCasesExport({
				variables
			})

			if (data && data.casesDownload) {
				const url = data.casesDownload.downloadUrl
				const a = document.createElement('a')
				a.style.display = 'none'
				a.href = url
				document.body.appendChild(a)
				a.click()
				window.URL.revokeObjectURL(url)
				document.body.removeChild(a)
			}
		} catch (error) {
			showError(downloadError)
			throw error
		} finally {
			setIsGenerating(false)
		}
	}

	return (
		<DownloadWrapper isGenerating={isGenerating}>
			<Button.Default
				text={
					window?.innerWidth < theme.legacyBreakpointsNumerical.sm
						? downloadButtonMobileText
						: downloadButtonText
				}
				onClick={downloadCases}
				colorType="blue"
				buttonType="text"
				disabled={isGenerating}
				isSmall
				icon={isGenerating ? Icon.Spinner : Icon.FileDownload}
				iconSize={16}
			/>
		</DownloadWrapper>
	)
}

export default ServiceRequestDownloadButton
