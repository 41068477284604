import { FC } from 'react'

import { IfilterItem } from '@damen/ui/lib/cjs/components/Filter/Group/types'
import { OptionType } from '@damen/ui/lib/cjs/components/Filters/types'
import { DropdownItemWithParent } from '@damen/ui/lib/cjs/components/Filter/Selected/types'
import { CustomProps, DefaultProps, TableHead } from '@components/Table/types'

export interface GenericOverviewProps {
	blok: any
	apiVariables?: any
	apiHookApolloLazyQuery: any
	filterAmount?: number
	filterResolver: FilterValueResolver
	headerComponentProps?: any
	onMobileSortingChanged: any
	onDataLoaded?: (data: any) => void
	onDataLoadedOnce?: (data: any[]) => void
	onRowClick?: (item: any) => void
	onIconClick?: (item: TableHeadColumn) => void
	parseAPIResponse: (item: any) => any[]
	parseFilters: (data: any, blok?: any) => any
	parseTableBody: (...rest) => TableBodyData
	paginateOnServer?: boolean
	tableHeadData: TableHead
	showRight?: boolean
	sortingResolver: SortingValueResolver
	fetchPolicy?: string

	//
	SkeletonComponent: JSX.Element
	HeaderComponent: FC<GenericHeaderProps>
	TableComponent: FC<DefaultProps | CustomProps>
	loadType?: 'default' | 'infinite'
}

export interface PageFilter {
	name: string
	label: string
	search: boolean
	options: DropdownItemWithParent[]
}

export interface PageFilterItem {
	label: string
	value: string
}

export interface TableHeadColumn {
	title: string | string[]
	field: GenericSortingValueResovler | string
	width: string | number
	onClick?: (item: TableHeadColumn) => void
	icon?: JSX.Element | boolean
	sortIcon?: JSX.Element | boolean
}

export interface TableBodyData {
	rows: DefaultTableBodyRow[] | CustomTableBodyRow[]
	total: number
}

export interface TableBodyColumn {
	title: string | React.ReactNode | React.ReactNode[]
	subline: string
	searchWord?: string
	type?:
		| 'categories'
		| 'states'
		| 'types'
		| 'warranties'
		| 'title'
		| 'date'
		| 'person'
	value?: string
	onClick?: () => void
}

export type TableItem = Record<string, any>

export interface Filter {
	name: string

	filters: string[]
}

export enum SortingType {
	asc = 'asc',
	desc = 'desc',
	none = 'none',
	custom = 'custom'
}

export interface Sorting {
	[name: string]: SortingType
}

export type FilterValueResolver = GenericFilterValueResolver

export interface GenericFilterValueResolver {
	[name: string]: (obj: Record<string, any>) => string | boolean
}

export type SortingValueResolver = GenericSortingValueResovler

export interface GenericSortingValueResovler {
	[name: string]: string
}

export type SortingKeys = string

// TODO: Import from Damen component library
export interface DefaultTableBodyRow {
	id: number | string
	onClick?: () => void | ((event: MouseEvent) => void) | KeyboardEvent
	columns: TableBodyColumn[]
}

export interface CustomTableBodyRow {
	id: number | string
	onClick?: () => void | ((event: MouseEvent) => void) | KeyboardEvent
	component?: FC<any>
	data?: any
}

export interface DefaultTableBody {
	rows: DefaultTableBodyRow[]
}

export interface GenericHeaderProps {
	blok: any
	desktopFilters: IfilterItem[] | null
	hasError: boolean
	mobileFilters: OptionType[] | null
	leftSide?: JSX.Element
	onFilterChange: (items: DropdownItemWithParent[]) => void
	onMobileSortingChanged: any
	onSearchChange: (event: string) => void
	onSortChange: (property: SortingKeys, order?: SortingType) => void
	rightSide?: JSX.Element
	searchText: string
	tableBody: TableBodyData
}

export interface QueryStringParameters {
	search?: string
	filters?: Record<string, unknown>
	page?: number | undefined
}
