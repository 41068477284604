import React from 'react'

import { Icon } from '@damen/ui'

import {
	SortIconAsc,
	SortIconDesc,
	SortIconText,
	SortIconWrapper
} from '@components/GenericOverview/styles'

import { SortIconProps } from './types'

export const SortIcon = ({
	text,
	hasIcon = false,
	direction
}: SortIconProps) => (
	<SortIconWrapper>
		{text && <SortIconText hasIcon={hasIcon}>{text}</SortIconText>}
		{direction === 'asc' && (
			<SortIconAsc>
				<Icon.LeftChevron fill="black" height="10" width="10" />
			</SortIconAsc>
		)}
		{direction === 'desc' && (
			<SortIconDesc>
				<Icon.LeftChevron fill="black" height="10" width="10" />
			</SortIconDesc>
		)}
	</SortIconWrapper>
)
