import ActiveFacets from '@components/ActiveFacets'
import SelectFacets from '@components/SelectFacets'
import StickyFilters from '@components/StickyFilters'
import { GetServiceRequestOverviewCasesQuery } from '@graphql/graphql'
import React from 'react'

enum CaseFilterKeys {
	vessel = 'vessel',
	company = 'company',
	type = 'type',
	category = 'category',
	system = 'system',
	status = 'status'
}

export interface CasesFilters {
	[CaseFilterKeys.vessel]?: string[]
	[CaseFilterKeys.company]?: string[]
	[CaseFilterKeys.type]?: string[]
	[CaseFilterKeys.category]?: string[]
	[CaseFilterKeys.system]?: string[]
	[CaseFilterKeys.status]?: string[]
}

interface ServiceRequestOverviewFiltersProps {
	facets: GetServiceRequestOverviewCasesQuery['cases']['facets']
	activeFilters: CasesFilters
	onChangeFilters: (filters: CasesFilters) => void
	vesselFilterLabel: string
	companyFilterLabel: string
	categoryFilterLabel: string
	statusFilterLabel: string
	filterSearchPlaceholder: string
	// eslint-disable-next-line react/require-default-props
	showVesselFilters?: boolean
	// eslint-disable-next-line react/require-default-props
	noSearchResultsLabel?: string
	// eslint-disable-next-line react/require-default-props
	showCompanyFilters?: boolean
}

const ServiceRequestOverviewFilters = ({
	facets: facetsData,
	activeFilters,
	onChangeFilters,
	vesselFilterLabel,
	companyFilterLabel,
	categoryFilterLabel,
	statusFilterLabel,
	filterSearchPlaceholder,
	noSearchResultsLabel,
	showVesselFilters = true,
	showCompanyFilters = true
}: ServiceRequestOverviewFiltersProps) => {
	const facets = [
		showVesselFilters && {
			key: CaseFilterKeys.vessel,
			label: vesselFilterLabel,
			search: true,
			options: facetsData?.asset || []
		},
		showCompanyFilters && {
			key: CaseFilterKeys.company,
			label: companyFilterLabel,
			search: true,
			options: facetsData?.company || []
		},
		{
			key: CaseFilterKeys.category,
			label: categoryFilterLabel,
			options: facetsData?.category || []
		},
		{
			key: CaseFilterKeys.status,
			label: statusFilterLabel,
			options: facetsData?.status ?? []
		}
	].filter(Boolean)

	const availableFilters = facetsData?.status.map((state) => state.id)
	const filteredActiveFilters: CasesFilters = {
		status: activeFilters?.status?.filter((name) =>
			availableFilters.includes(name)
		),
		vessel: activeFilters?.vessel,
		company: activeFilters?.company,
		category: activeFilters?.category
	}

	return (
		<StickyFilters>
			<SelectFacets
				facets={facets}
				activeFilters={filteredActiveFilters}
				onChangeFilters={onChangeFilters}
				noSearchResultsLabel={noSearchResultsLabel}
				filterSearchPlaceholder={filterSearchPlaceholder}
			/>

			<ActiveFacets
				facets={facets}
				activeFilters={filteredActiveFilters}
				onChangeFilters={onChangeFilters}
			/>
		</StickyFilters>
	)
}

export default ServiceRequestOverviewFilters
