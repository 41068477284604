import React, { useCallback } from 'react'
import { ApolloClient, NormalizedCacheObject } from '@apollo/client'

import {
	GetServiceRequestPageDataQuery,
	GetServiceRequestPageDataDocument,
	Permissions,
	useGetAssetDetailsQuery
} from '@graphql/graphql'
import { useLocale } from '@hooks/useLocale'
import Banner from '@components/Banner'
import PageHead from '@components/PageHead'
import ServiceRequestOverview from '@components/ServiceRequestOverview'
import useAuthorization from '@hooks/useAuthorization'

import { ServiceRequestOverviewProps } from './types'

export async function GetPageItem(
	apolloClient: ApolloClient<NormalizedCacheObject>,
	locale: string
) {
	const pageData = await apolloClient.query<GetServiceRequestPageDataQuery>({
		query: GetServiceRequestPageDataDocument,
		variables: {
			language: locale
		}
	})

	return pageData?.data?.PageItem ?? {}
}

const ComponentDictionary = {
	header: Banner,
	serviceRequestOverview: ServiceRequestOverview
}

const ServiceRequests = ({
	pageData,
	assetId
}: ServiceRequestOverviewProps) => {
	useAuthorization(Permissions.ServiceRequestsRead)

	// Contexts
	const { locale } = useLocale()

	// API calls
	// No error state as this data is only used to display the vessel name as the subtitle in the header
	// Therefore no suitable / usefull error state can be implemented
	const { data: assetDetailsData, loading: assetDetailsLoading } =
		useGetAssetDetailsQuery({
			variables: {
				id: assetId,
				language: locale
			},
			skip: assetId == null
		})
	const subtitle = assetDetailsData?.asset?.name

	// Cached render component callback
	const renderComponent = useCallback(
		(blok: any) => {
			if (typeof ComponentDictionary[blok.component] !== 'undefined') {
				const props: any = {
					key: blok._uid,
					blok,
					loading: assetDetailsLoading
				}

				if (blok.component === 'header') {
					props.subtitle = subtitle
					props.sidebarBlocks = pageData?.content?.sidebarBlocks
					props.details = assetDetailsData?.asset
				}

				if (blok.component === 'serviceRequestOverview') {
					props.assetId = assetId
					props.statusTitle =
						pageData?.content?.sidebarBlocks[0]?.title
				}

				return React.createElement(
					ComponentDictionary[blok.component],
					props
				)
			}

			return null
		},
		[
			assetDetailsData?.asset,
			assetDetailsLoading,
			assetId,
			pageData?.content?.sidebarBlocks,
			subtitle
		]
	)

	return (
		<>
			<PageHead
				seoData={pageData?.content?.seoMetatags}
				headerColor="blue"
			/>
			{pageData?.content?.blocks.map((blok) => (
				<section key={blok._uid}>{renderComponent(blok)}</section>
			))}
		</>
	)
}

export default ServiceRequests
