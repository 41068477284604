import React from 'react'

import { Case } from '@graphql/graphql'

import { IfilterItem } from '@damen/ui/lib/cjs/components/Filter/Group/types'
import { TableHead } from '@components/Table/types'

import { validatePagefilterOption } from '@components/GenericOverview/helpers'
import {
	FilterValueResolver,
	SortingType,
	TableBodyData,
	SortingValueResolver
} from '@components/GenericOverview/types'
import Status from '@components/UI/Status/index'

import caseStatusColorMapping from '@utils/colors/caseStatusColorMapping'

import {
	CasePageFilters,
	GoToDetailFnCase,
	CaseData,
	CaseFilterNames,
	CaseColumnKeys,
	CaseColumnResolver
} from './types'

// Resolvers
export const filterResolver: FilterValueResolver = {
	category: (caseModel: Case) => caseModel.category.name,
	system: (caseModel: Case) => caseModel.severity?.name,
	status: (caseModel: Case) => caseModel.state.name
}

export const sortingResolver: SortingValueResolver = {
	category: 'category.name',
	dateFormatted: 'createdAt',
	requestedBy: 'requestor.name',
	status: 'state.name',
	title: 'title'
}

export const tableColumnResolver: CaseColumnResolver = {
	externalId: CaseColumnKeys.externalId,
	title: CaseColumnKeys.title,
	category: CaseColumnKeys.category,
	dateFormatted: CaseColumnKeys.dateFormatted,
	requestedBy: CaseColumnKeys.requestedBy,
	status: CaseColumnKeys.status
}

// Helper functions
// Map data from request to filter_selected
// Adding filters to filter data can be done here 👇
export const mapFilters = (
	items: CaseData[],
	resolver: FilterValueResolver,
	names: CaseFilterNames
): IfilterItem[] => {
	if (!items) {
		return []
	}

	const pageFilter: CasePageFilters = {
		vessels: {
			label: names.vessel,
			name: CaseColumnKeys.vessel,
			search: true,
			options: []
		},
		types: {
			label: names.type,
			name: CaseColumnKeys.type,
			search: true,
			options: []
		},
		categories: {
			label: names.category,
			name: CaseColumnKeys.category,
			search: false,
			options: []
		},
		systems: {
			label: names.system,
			name: CaseColumnKeys.system,
			search: true,
			options: []
		},
		states: {
			label: names.status,
			name: CaseColumnKeys.status,
			search: false,
			options: []
		}
	}

	const tempVessels: string[] = []
	const tempTypes: string[] = []
	const tempCategories: string[] = []
	const tempSystems: string[] = []
	const tempStatus: string[] = []

	items.forEach((item) => {
		const category = resolver.category(item) as string
		const status = resolver.status(item) as string
		const vessel = resolver?.vessel && (resolver.vessel(item) as string)
		const type = resolver?.type && (resolver.type(item) as string)
		const system = resolver.system(item) as string

		validatePagefilterOption(
			pageFilter.categories,
			tempCategories,
			category
		)
		validatePagefilterOption(pageFilter.states, tempStatus, status)
		if (pageFilter.vessels) {
			validatePagefilterOption(pageFilter.vessels, tempVessels, vessel)
		}
		if (pageFilter.types) {
			validatePagefilterOption(pageFilter.types, tempTypes, type)
		}
		validatePagefilterOption(pageFilter.systems, tempSystems, system)
	})

	return Object.keys(pageFilter).map((item: string) => {
		const filterItem = pageFilter[item]
		return {
			label: filterItem.label,
			name: filterItem.name,
			search: filterItem.search,
			id: filterItem.name,
			options: filterItem.options.sort((n1, n2) =>
				n1.name > n2.name ? 1 : -1
			)
		}
	})
}

export const parseTableHeadData = (blok: any): TableHead => {
	const {
		tableDescription: blokDescription,
		tableCategory,
		tableDate,
		tableRequestedBy,
		tableStatus
	} = blok

	const tableDescription = blokDescription?.split('/')[0] ?? '[Description]'
	const tableSystem = blokDescription?.split('/')[1] ?? '[System]'

	return {
		color: 'black',
		columns: [
			{
				title: [tableDescription, tableSystem],
				field: tableColumnResolver.title,
				width: '30%',
				sortIcon: true,
				icon: false
			},
			{
				title: tableCategory,
				field: tableColumnResolver.category,
				width: '15%',
				sortIcon: true,
				icon: false
			},
			{
				title: tableDate,
				field: tableColumnResolver.dateFormatted,
				width: '15%',
				sortIcon: true,
				icon: false
			},
			{
				title: tableRequestedBy,
				field: tableColumnResolver.requestedBy,
				width: '20%',
				sortIcon: true,
				icon: false
			},
			{
				title: tableStatus,
				field: tableColumnResolver.status,
				width: '20%',
				sortIcon: true,
				icon: true
			}
		]
	}
}

export const buildTableBody = (
	cases: CaseData[],
	goToCaseDetail: GoToDetailFnCase,
	searchWord: string
): TableBodyData => {
	const tableBodyData: TableBodyData = { rows: [], total: cases.length }
	cases.forEach((item) => {
		const titleCaseSubline = item.severity?.name || ''
		tableBodyData.rows.push({
			id: item.id,
			onClick: goToCaseDetail(item),
			columns: [
				{
					title: item.title,
					subline: titleCaseSubline,
					searchWord
				},
				{
					title: item.category.name,
					subline: '',
					searchWord
				},
				{
					title: item.createdAtFormatted,
					subline: '',
					searchWord
				},
				{
					title: item.requestor?.name,
					subline: '',
					searchWord
				},
				{
					title: (
						<Status
							text={item.state.name}
							state={caseStatusColorMapping[item.state.id]}
						/>
					),
					subline: '',
					searchWord
				}
			]
		})
	})

	return tableBodyData
}

export const onMobileSortingChanged = (
	ev: string,
	updateSorting: (property: CaseColumnKeys, order?: SortingType) => void
) => {
	let order: SortingType = null
	let property: CaseColumnKeys = null
	const orderValue = ev.toLowerCase()
	const propertyValue = orderValue
		.replace(`-${SortingType.asc}`, '')
		.replace(`-${SortingType.desc}`, '')

	if (orderValue.includes(SortingType.asc)) {
		order = SortingType.asc
	} else if (orderValue.includes(SortingType.desc)) {
		order = SortingType.desc
	} else if (orderValue.includes(SortingType.none)) {
		order = SortingType.none
	}

	if (propertyValue.includes(CaseColumnKeys.category)) {
		property = CaseColumnKeys.category
	} else if (propertyValue.includes(CaseColumnKeys.dateFormatted)) {
		property = CaseColumnKeys.dateFormatted
	} else if (propertyValue.includes(CaseColumnKeys.requestedBy)) {
		property = CaseColumnKeys.requestedBy
	} else if (propertyValue.includes(CaseColumnKeys.status)) {
		property = CaseColumnKeys.status
	} else if (propertyValue.includes(CaseColumnKeys.title)) {
		property = CaseColumnKeys.title
	}

	updateSorting(property, order)
}
