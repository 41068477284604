import { PageFilter } from '@components/GenericOverview/types'

import { Case } from '@graphql/graphql'

import { ServiceRequestOverviewStoryblok } from '@graphql/storyblokcomponents'

export interface ServiceRequestOverviewProps {
	blok: ServiceRequestOverviewStoryblok
	assetId?: string
	locale: string
	statusTitle: string
}

export interface CasePageFilters {
	categories: PageFilter
	states: PageFilter
	sort?: PageFilter
	vessels: PageFilter
	types: PageFilter
	systems: PageFilter
}

export type GoToDetailFnCase = (n: Pick<Case, 'id'>) => any

export type TableCase = Pick<
	Case,
	| 'title'
	| 'category'
	| 'requestor'
	| 'state'
	| 'createdAtFormatted'
	| 'id'
	| 'severity'
>

export type CaseData = Pick<
	Case,
	| 'title'
	| 'category'
	| 'requestor'
	| 'state'
	| 'createdAtFormatted'
	| 'id'
	| 'severity'
	| 'asset'
>

export enum CaseColumnKeys {
	category = 'category',
	title = 'title',
	dateFormatted = 'dateFormatted',
	requestedBy = 'requestedBy',
	status = 'status',
	vessel = 'vessel',
	system = 'system',
	type = 'type',
	externalId = 'externalId'
}

export interface CaseColumnResolver {
	title: CaseColumnKeys
	category: CaseColumnKeys
	dateFormatted: CaseColumnKeys
	requestedBy: CaseColumnKeys
	status: CaseColumnKeys
	externalId: CaseColumnKeys
}

export interface CaseFilterValueResolver {
	vessel?: (caseModel: CaseData) => string
	type?: (caseModel: CaseData) => string
	category: (caseModel: CaseData) => string
	system: (caseModel: CaseData) => string
	status: (caseModel: CaseData) => string
	[key: string]: (caseModel: CaseData) => string
}

export interface CaseFilterNames {
	vessel: string
	type: string
	category: string
	system: string
	status: string
}

export interface CaseSortingValueResolver {
	category: string
	dateFormatted: string
	requestedBy: string
	status: string
	title: string
	[key: string]: string
}
