import SkeletonLoader from '@components/UI/SkeletonLoader'
import React from 'react'
import {
	Content,
	StatusItem,
	StatusItemTitle,
	Title,
	Wrapper
} from '@components/UI/StatusExplanation/styles'
import { TextWrapper } from './styles'

const StatusExplanationSkeleton = ({ rows = 13 }) => {
	const items = rows
	const itemId = []
	for (let i = 0; i < rows; i += 1) {
		itemId.push(i)
	}

	return (
		<Wrapper>
			<Title>
				<SkeletonLoader height={30} width={223} />
			</Title>
			<Content>
				{[...Array(items)].map((item, i) => {
					return (
						<StatusItem key={itemId[i]}>
							<StatusItemTitle>
								<SkeletonLoader height={24} />
							</StatusItemTitle>
							<TextWrapper>
								<SkeletonLoader count={2} />
							</TextWrapper>
						</StatusItem>
					)
				})}
			</Content>
		</Wrapper>
	)
}

export default StatusExplanationSkeleton
