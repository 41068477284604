/* eslint-disable react/require-default-props */
import React, { useCallback } from 'react'
import { useRouter } from 'next/router'

import { SortIcon } from '@components/UI/SortIcon'
import { Icon, theme } from '@damen/ui'
import { TableColumn } from '@components/Table/types'
import { GetServiceRequestOverviewCasesQuery } from '@graphql/graphql'
import { SortingType } from '@components/GenericOverview/types'
import Table from '@components/Table'
import {
	ASSET_SERVICEREQUESTS_DETAIL,
	SERVICEREQUESTS_DETAIL,
	SLUG_SERVICEREQUESTS
} from '@src/constants/Routes'
import Status from '@components/UI/Status'
import caseStatusColorMapping from '@utils/colors/caseStatusColorMapping'

import {
	TableIconWrapper,
	TableItemTitle
} from '@components/GenericOverview/styles'
import { tableColumnResolver } from './helpers'

export interface ServiceRequestOverviewTableOrder {
	[key: string]: SortingType
}

interface ServiceRequestOverviewTableProps {
	items: GetServiceRequestOverviewCasesQuery['cases']['items']
	searchWord?: string
	orderBy?: ServiceRequestOverviewTableOrder
	onOrderByChange?: (orderBy: ServiceRequestOverviewTableOrder) => void
	descriptionColumnTitle: string
	systemColumnTitle: string
	categoryColumnTitle: string
	dateColumnTitle: string
	requestedByColumnTitle: string
	statusColumnTitle: string
	numberColumnTitle?: string
	onIconClick?: (item: TableColumn) => void
}

const ServiceRequestOverviewTable = ({
	items,
	searchWord,
	orderBy,
	onOrderByChange,
	descriptionColumnTitle,
	systemColumnTitle,
	categoryColumnTitle,
	dateColumnTitle,
	requestedByColumnTitle,
	statusColumnTitle,
	numberColumnTitle,
	onIconClick
}: ServiceRequestOverviewTableProps) => {
	const router = useRouter()
	const goToCaseDetail = useCallback(
		(item: any) => {
			const assetId = router.query?.id?.toString()
			if (assetId) {
				router.push({
					pathname: ASSET_SERVICEREQUESTS_DETAIL,
					query: {
						id: assetId,
						slug: SLUG_SERVICEREQUESTS,
						slugdetail: item.id
					}
				})
			} else {
				router.push({
					pathname: SERVICEREQUESTS_DETAIL,
					query: { slug: item.id }
				})
			}
		},
		[router]
	)

	const sortable = (column: TableColumn) => ({
		...column,
		sortIcon: (
			<SortIcon
				direction={orderBy?.[column.field]}
				text={
					typeof column.title === 'string'
						? column.title
						: column.title?.join(' / ')
				}
			/>
		),
		icon: column.icon ? (
			<TableIconWrapper
				onClick={(event) => {
					event.stopPropagation()
					if (onIconClick) {
						onIconClick(column)
					}
				}}
			>
				<Icon.Info fill={theme.colors.grey} height="16" width="16" />
			</TableIconWrapper>
		) : null,
		onClick: () => {
			onOrderByChange({
				[column.field]:
					orderBy?.[column.field] === SortingType.asc
						? SortingType.desc
						: SortingType.asc
			})
		}
	})

	const withIcon = (column: TableColumn) => ({
		...column,
		icon: column.icon ? (
			<TableIconWrapper
				onClick={(event) => {
					event.stopPropagation()
					if (onIconClick) {
						onIconClick(column)
					}
				}}
			>
				<Icon.Info fill={theme.colors.grey} height="16" width="16" />
			</TableIconWrapper>
		) : null
	})

	return (
		<Table
			head={{
				columns: [
					{
						title: numberColumnTitle ?? '',
						field: tableColumnResolver.externalId,
						width: '10%',
						icon: false
					},
					sortable({
						title: systemColumnTitle
							? [descriptionColumnTitle, systemColumnTitle]
							: descriptionColumnTitle,
						field: tableColumnResolver.title,
						width: '30%',
						icon: false
					}),
					{
						title: categoryColumnTitle,
						field: tableColumnResolver.category,
						width: '15%',
						icon: false
					},
					sortable({
						title: dateColumnTitle,
						field: tableColumnResolver.dateFormatted,
						width: '10%',
						icon: false
					}),
					{
						title: requestedByColumnTitle,
						field: tableColumnResolver.requestedBy,
						width: '17%',
						icon: false
					},
					withIcon({
						title: statusColumnTitle,
						field: tableColumnResolver.status,
						width: '18%',
						icon: true
					})
				]
			}}
			body={{
				rows: items.map((item) => ({
					id: item.id,
					onClick: () => goToCaseDetail(item),
					columns: [
						{
							title: item.externalId,
							subline: '',
							searchWord
						},
						{
							title: (
								<TableItemTitle title={item.title}>
									{item.title}
								</TableItemTitle>
							),
							subline: item.severity?.name || '',
							searchWord
						},
						{
							title: item.category.name,
							subline: '',
							searchWord
						},
						{
							title: item.createdAtFormatted,
							subline: '',
							searchWord
						},
						{
							title: item.requestor?.name,
							subline: '',
							searchWord
						},
						{
							title: (
								<Status
									text={item.state.name}
									state={
										caseStatusColorMapping[item.state.id]
									}
								/>
							),
							subline: '',
							searchWord
						}
					]
				}))
			}}
		/>
	)
}

export default ServiceRequestOverviewTable
