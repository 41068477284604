import { Permissions } from '@graphql/graphql'
import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { usePermissions } from './usePermission'

const useAuthorization = (permissionNeeded: Permissions) => {
	const router = useRouter()
	const { hasAcquired, isAuthenticated, isLoading } =
		usePermissions(permissionNeeded)

	useEffect(() => {
		if (isLoading) {
			return
		}

		if (!isAuthenticated) {
			router.replace({
				pathname: '/401',
				query: {
					type: 'unauthenticated'
				}
			})
		}

		if (!hasAcquired) {
			router.replace({
				pathname: '/401',
				query: {
					type: 'unauthorized'
				}
			})
		}
	}, [router, hasAcquired, isAuthenticated, isLoading])
}

export default useAuthorization
