import React from 'react'
import styled from 'styled-components'
import {
	colors,
	fonts,
	typography,
	legacyMediaQueries
} from '@damen/ui/lib/cjs/styles'

import { TableColumn, TableHeadingStyled, DefaultProps } from './types'

export const TableElement = styled.table`
	width: 100%;
	table-layout: fixed;
	border-collapse: collapse;
	font-family: ${fonts.body};
	font-size: ${typography.fontSizeTextSmall}px;
	color: ${colors.marineBlack};
	line-height: ${typography.lineHeightTextSmall};
`

export const TableHeadElement = styled.thead`
	position: relative;
	display: none;
	color: ${colors.marineBlack};

	@media ${legacyMediaQueries.md} {
		display: table-header-group;
		font-weight: ${typography.fontWeightMedium};
	}
`

export const TableHeadRow = styled.tr`
	position: relative;
	border-bottom: 1px solid ${colors.greyAccentLight};
`

export const TableHeading = styled.th.withConfig({
	shouldForwardProp: (prop) => !['width'].includes(prop)
})<TableHeadingStyled>`
	position: relative;
	text-align: left;
	padding: 8px 8px 8px 0;
	font-weight: ${typography.fontWeightMedium};
	cursor: ${({ onClick }) => (onClick ? 'pointer' : 'auto')};

	&:last-child {
		padding: 8px 0;
	}

	@media ${legacyMediaQueries.md} {
		height: 48px;
		padding: 8px 40px 8px 0;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		width: ${({ width }) => width || 'auto'};

		&:last-child {
			padding: 8px 0;
		}
	}
`

export const TableRow = styled.tr`
	position: relative;
	border-bottom: 1px solid ${colors.greyAccentLight};
	cursor: ${({ onClick }) => (onClick ? 'pointer' : 'inherit')};

	@media ${legacyMediaQueries.mdMax} {
		border-top: 1px solid ${colors.greyAccentLight};
	}

	&:hover,
	&:focus-within {
		background-color: ${colors.blueIce};
		outline: none;

		@media ${legacyMediaQueries.md} {
			td {
				&:first-child {
					&::after {
						content: '';
						position: absolute;
						top: 0;
						width: 16px;
						height: 100%;
						left: -16px;
						background-color: ${colors.blueIce};
					}
				}

				&:last-child {
					&::after {
						content: '';
						position: absolute;
						top: 0;
						width: 16px;
						height: 100%;
						right: -16px;
						background-color: ${colors.blueIce};
					}
				}
			}
		}
	}
`

export const TableBodyElement = styled.tbody`
	position: relative;
`

export const TableColumnnSubline = styled.span`
	display: block;

	@media ${legacyMediaQueries.md} {
		color: ${colors.grey};
	}
`

export const TableColumnElement = styled.td`
	position: relative;
	display: flex;
	padding: 6px 0;
	cursor: ${({ onClick }) => (onClick ? 'pointer' : 'inherit')};

	&:first-child {
		padding-top: 24px;
	}

	&:last-child {
		padding-bottom: 24px;
		padding-right: 0;
	}

	&:focus {
		outline: none;
	}

	@media ${legacyMediaQueries.md} {
		display: table-cell;
		height: 64px;
		padding: 0 40px 0 0;

		&:first-child {
			padding-top: 0;
		}

		&:last-child {
			padding-bottom: 0;
			padding-right: 0;
		}
	}
`

export const TableMobileHeader = styled.span`
	min-width: 90px;
	width: 90px;
	margin-right: 38px;
	font-weight: ${typography.fontWeightMedium};

	@media ${legacyMediaQueries.md} {
		display: none;
	}
`

export const TableContentWrapper = styled.div`
	@media ${legacyMediaQueries.md} {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		width: 100%;
	}
`

export const TableMobileWrapper = styled.div`
	width: 100%;

	> div:first-child {
		padding-bottom: 24px;
	}

	@media ${legacyMediaQueries.md} {
		display: none;
	}
`

export const TableMobileContentWrapper = styled.div`
	display: flex;
`

export const TableDesktopWrapper = styled.div`
	display: none;

	@media ${legacyMediaQueries.md} {
		display: block;
	}
`

export const showTitles = (column: TableColumn) => {
	if (column !== null || column !== undefined) {
		if (
			typeof column?.title !== 'string' &&
			column &&
			column.title != null
		) {
			return column?.title?.map((titleItem, index, arr) =>
				index !== arr.length - 1 ? `${titleItem} / ` : `${titleItem}`
			)
		}
	}
	return column?.title ?? ''
}

const Table: React.FC<DefaultProps> = ({ head, body, ...rest }) => (
	<TableElement {...rest} data-testid="TableElement">
		{head && (
			<TableHeadElement data-testid="TableHeadElement">
				<TableHeadRow data-testid="TableHeadRow">
					{head.columns.map((column: TableColumn, index: number) => {
						const key = `${column.field}-${index}`
						return (
							<TableHeading
								data-testid="TableHeading"
								tabIndex={column.onClick ? 0 : -1}
								onKeyPressCapture={column.onClick}
								onClick={column.onClick}
								onMouseOver={column.onMouseOver}
								onMouseOut={column.onMouseOut}
								width={column.width ? column.width : 'auto'}
								key={key}
							>
								{showTitles(column)}

								{column.icon && column.icon}
								<span>{column.sortIcon}</span>
							</TableHeading>
						)
					})}
				</TableHeadRow>
			</TableHeadElement>
		)}

		<TableBodyElement data-testid="TableBodyElement">
			{body.rows.map((row) => (
				<TableRow
					data-testid="TableRow"
					key={row.id}
					tabIndex={0}
					onKeyPressCapture={row.onClick}
					onClick={row.onClick}
				>
					{row.columns.map((column, index) => {
						const key = `${column.title}-${index}`
						if (
							head &&
							head.columns.length > 0 &&
							Array.isArray(head.columns[index].title)
						) {
							const headColumn = head.columns[index]
							return (
								<TableColumnElement
									key={key}
									tabIndex={column.onClick ? 0 : -1}
									onKeyPressCapture={column.onClick}
									onClick={column.onClick}
									data-testid="TableBodyColumn"
								>
									{/* MOBILE */}
									<TableMobileWrapper>
										<TableContentWrapper>
											{column.title}
										</TableContentWrapper>

										<TableMobileContentWrapper>
											<TableMobileHeader>
												{headColumn.title[0]}
											</TableMobileHeader>

											<TableContentWrapper>
												{column.subline ? (
													<TableColumnnSubline>
														{column.subline}
													</TableColumnnSubline>
												) : (
													'-'
												)}
											</TableContentWrapper>
										</TableMobileContentWrapper>
									</TableMobileWrapper>

									{/* DESKTOP */}
									<TableDesktopWrapper data-testid="TableBodyColumnContent">
										<TableMobileHeader>
											{headColumn.title}
										</TableMobileHeader>

										<TableContentWrapper data-testid="TableBodyRowCell">
											{column.title}
											{column.subline ? (
												<TableColumnnSubline>
													{column.subline}
												</TableColumnnSubline>
											) : (
												''
											)}
										</TableContentWrapper>
									</TableDesktopWrapper>
								</TableColumnElement>
							)
						}
						return (
							<TableColumnElement
								key={key}
								tabIndex={column.onClick ? 0 : -1}
								onKeyPressCapture={column.onClick}
								onClick={column.onClick}
								data-testid="TableBodyColumn"
							>
								{head && head.columns.length > 0 && (
									<TableMobileHeader>
										{head.columns[index].title}
									</TableMobileHeader>
								)}

								<TableContentWrapper>
									{column.title}
									{column.subline ? (
										<TableColumnnSubline>
											{column.subline}
										</TableColumnnSubline>
									) : (
										''
									)}
								</TableContentWrapper>
							</TableColumnElement>
						)
					})}
				</TableRow>
			))}
		</TableBodyElement>
	</TableElement>
)

export default Table
